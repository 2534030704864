<template>
  <div
    class="bg-white border border-primary border-2"
  >
    <div class="p-3 p-lg-5 col-12 col-lg-9 mx-auto">
      <!--                             <div class="d-flex mt-3 mb-1"> -->
      <!--                                 <label class="m-0 flex-shrink-0 m-0">會員等級：</label> -->
      <!--                                 <span class="d-block">{{memberDetail.grade}}</span> -->
      <!--                             </div>                             -->

      <!-- 會員名稱 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-center g-0"
      >
        <Input
          id="showName"
          v-model:input="state.showName"
          type="text"
          label="會員名稱"
          direction="row"
          :required="true"
          :maxlength="30"
          autocomplete="on"
          placeholder="請輸入會員名稱"
          :err-message="v$.showName?.$errors[0]?.$message || ''"
          @focus="v$.showName.$touch"
          @enter="updateMemberDetail"
        />
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">會員名稱：</label>
        <span class="col-8 text-start">{{ memberDetail.showName ?? '' }}</span>
      </div>
      <!-- 會員姓名 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-start g-0"
      >
        <label
          class="col-4 pe-2 text-end mt-2"
        >會員姓名 <span :class="$style.required">*</span>：</label>
        <div class="col-8 d-flex">
          <Input
            id="lastName"
            v-model:input="state.lastName"
            type="text"
            class="me-2"
            direction="column"
            :required="true"
            :maxlength="30"
            autocomplete="on"
            placeholder="請輸入姓氏"
            :err-message="v$.lastName?.$errors[0]?.$message || ''"
            @focus="v$.lastName.$touch"
            @enter="updateMemberDetail"
          />
          <Input
            id="firstName"
            v-model:input="state.firstName"
            type="text"
            direction="column"
            :required="true"
            :maxlength="30"
            autocomplete="on"
            placeholder="請輸入名字"
            :err-message="v$.firstName?.$errors[0]?.$message || ''"
            @focus="v$.firstName.$touch"
            @enter="updateMemberDetail"
          />
        </div>
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">會員姓名：</label>
        <span
          class="col-8 text-start"
        >{{ memberDetail.lastName ?? '' }}
          {{ memberDetail.firstName ?? '' }}</span>
      </div>
      <!-- 會員性別 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-center position-relative g-0"
      >
        <label class="col-4 text-end pe-2">會員性別：</label>
        <div class="col-8">
          <div class="d-flex flex-column">
            <select
              id="gender"
              v-model="state.gender"
              class="form-select"
              :class="[$style[`input`], { 'border-danger': v$.gender?.$error }]"
              placeholder="請輸入性別"
              @focus="v$.gender.$touch"
            >
              <option :value="''">
                請選擇性別
              </option>
              <option value="男性">
                男性
              </option>
              <option value="女性">
                女性
              </option>
              <option value="多元性別">
                多元性別
              </option>
            </select>
          </div>
          <small
            class="text-danger position-absolute end-0"
            :class="[
              $style[`error-text`],
              { [$style[`show`]]: v$.gender?.$errors[0]?.$message !== '' }
            ]"
          >{{ v$.gender?.$errors[0]?.$message || '' }}</small>
        </div>
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">會員性別：</label>
        <span class="col-8 text-start">{{ memberDetail.gender || '-' }}</span>
      </div>
      <!-- 會員生日 -->
      <div
        v-if="isEdit && !memberDetail.birthday"
        class="row mb-3 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">會員生日：</label>
        <div class="col-8 position-relative">
          <Datepicker
            v-model="state.birthday"
            class="form-control bg-transparent"
            :class="{ 'border-danger': v$.birthday?.$error }"
            :input-format="'yyyy-MM-dd'"
            :clearable="false"
            placeholder="請輸入會員生日"
            :style="{
              '--vdp-selected-bg-color': '#cbbbae',
              '--vdp-hover-bg-color': '#d4c4b7'
            }"
            @focus="v$.birthday.$touch"
          />
          <small
            class="text-danger position-absolute end-0"
            :class="[
              $style[`error-text`],
              { [$style[`show`]]: v$.birthday?.$errors[0]?.$message !== '' }
            ]"
          >{{ v$.birthday?.$errors[0]?.$message || '' }}</small>
        </div>
        <small class="col-8 offset-4 text-danger text-start">
          生日儲存後不可修改，請確認是否填寫正確
        </small>
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">會員生日：</label>
        <span class="col-8 text-start">{{ memberDetail.birthday || '-' }}</span>
      </div>
      <!-- 聯絡手機 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-start g-0"
      >
        <Input
          id="cell"
          v-model:input="state.cell"
          type="text"
          label="聯絡手機"
          direction="row"
          :required="true"
          autocomplete="on"
          placeholder="請輸入聯絡手機"
          :err-message="v$.cell?.$errors[0]?.$message || ''"
          @focus="v$.cell.$touch"
          @enter="updateMemberDetail"
        />
      </div>
      <div
        v-else
        class="row mb-4 align-items-start g-0"
      >
        <label class="col-4 text-end pe-2">聯絡手機：</label>
        <div class="col-8 text-start">
          {{ memberDetail.cell || '-' }}
        </div>
      </div>
      <!-- E-mail -->
      <div class="row mb-4 align-items-start g-0">
        <label class="col-4 text-end pe-2">E-mail：</label>
        <div class="col-8 text-start">
          {{ memberDetail.email || '-' }}
        </div>
      </div>
      <!-- 市內電話 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-center g-0"
      >
        <Input
          id="phone"
          v-model:input="state.phone"
          type="tel"
          label="市內電話"
          direction="row"
          :required="false"
          autocomplete="on"
          placeholder="請輸入市內電話"
          :err-message="v$.phone?.$errors[0]?.$message || ''"
          @focus="v$.phone.$touch"
          @enter="updateMemberDetail"
        />
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">市內電話：</label>
        <span class="col-8 text-start">{{ memberDetail.phone || '-' }}</span>
      </div>
      <!-- 所在縣市 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">所在縣市：</label>
        <div class="col-8 position-relative">
          <div class="d-flex flex-column">
            <select
              id="cities"
              v-model="state.county"
              class="form-select"
              :class="[$style[`input`], { 'border-danger': v$.county?.$error }]"
              placeholder="請選擇縣"
              @focus="v$.county.$touch"
            >
              <option :value="''">
                請選擇縣
              </option>
              <option
                v-for="city in cities"
                :key="city.CityName"
                :value="city.CityName"
              >
                {{ city.CityName }}
              </option>
            </select>
          </div>
          <small
            class="text-danger position-absolute end-0"
            :class="[
              $style[`error-text`],
              { [$style[`show`]]: v$.gender?.$errors[0]?.$message !== '' }
            ]"
          >{{ v$.gender?.$errors[0]?.$message || '' }}</small>
        </div>
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">所在縣市：</label>
        <span class="col-8 text-start">{{ memberDetail.county || '-' }}</span>
      </div>
      <!-- 通訊地址 -->
      <div
        v-if="isEdit"
        class="row mb-4 align-items-center g-0"
      >
        <Input
          id="address"
          v-model:input="state.address"
          type="address"
          label="通訊地址"
          direction="row"
          :required="false"
          autocomplete="on"
          placeholder="請輸入所在地址"
          :err-message="v$.address?.$errors[0]?.$message || ''"
          @focus="v$.address.$touch"
        />
      </div>
      <div
        v-else
        class="row mb-4 align-items-center g-0"
      >
        <label class="col-4 text-end pe-2">通訊地址：</label>
        <span class="col-8 text-start">{{ memberDetail.address || '-' }}</span>
      </div>
      <div class="row mb-4 align-items-center g-0">
        <label class="col-4 text-end pe-2">購物金餘額：</label>
        <span class="col-8 text-start">{{
          memberDetail.bonusAmt ? getThousands(memberDetail.bonusAmt) : 0
        }}</span>
      </div>

      <div class="row mb-4 align-items-center g-0">
        <label class="col-4 text-end pe-2">累計消費額：</label>
        <span class="col-8 text-start">{{
          memberDetail.consAmt ? getThousands(memberDetail.consAmt) : 0
        }}</span>
      </div>

      <div class="row mb-4 align-items-center g-0">
        <label class="col-4 text-end pe-2">最後消費日期：</label>
        <span class="col-8 text-start">{{
          memberDetail.lastConsTime ?? '-'
        }}</span>
      </div>

      <div
        v-if="isEdit"
        class="d-flex justify-content-center my-3"
      >
        <button
          class="btn btn-outline-primary rounded-0"
          type="button"
          @click="toggleEdit"
        >
          取消
        </button>
        <button
          class="btn btn-primary text-white rounded-0 ms-2"
          type="button"
          @click="updateMemberDetail"
        >
          儲存
        </button>
      </div>
      <button
        v-else
        class="btn btn-primary text-white rounded-0 my-3"
        type="button"
        @click="toggleEdit"
      >
        編輯
      </button>
    </div>
  </div>
</template>
<script>
import cities from '@/hooks/cities.json'
import Input from '@/components/Input.vue'
import Datepicker from 'vue3-datepicker'
import useVuelidate from '@vuelidate/core'
import {
  requiredHelper,
  emailHelper,
  mobileHelper,
  numericHelper
} from '@/hooks/useVuelidate.js'
import { useStore } from 'vuex'
import { computed, ref, inject } from 'vue'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'MemberDetail',
  components: {
    Input,
    Datepicker
  },
  async setup (props) {
    const { getters, dispatch } = useStore()
    const { getErrorMessage, getSuccessMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])
    const memberDetail = computed(() => getters['member/getMemberDetail'])

    const setAlert = inject('setAlert')

    const state = ref({
      showName: memberDetail.value.showName || null,
      lastName: memberDetail.value.lastName || null,
      firstName: memberDetail.value.firstName || null,
      gender: memberDetail.value.gender || '',
      birthday: memberDetail.value.birthday
        ? new Date(memberDetail.value.birthday)
        : null,
      cell: memberDetail.value.cell || null,
      email: memberDetail.value.email || null,
      phone: memberDetail.value.phone || '',
      county: memberDetail.value.county || '',
      address: memberDetail.value.address || ''
    })

    const rules = {
      showName: { requiredHelper },
      lastName: { requiredHelper },
      firstName: { requiredHelper },
      gender: {},
      birthday: {},
      cell: { requiredHelper, mobileHelper },
      email: { requiredHelper, emailHelper },
      phone: { numericHelper },
      county: {},
      address: {}
    }

    const v$ = useVuelidate(rules, state)

    const resetState = () => {
      state.value = {
        showName: memberDetail.value.showName || null,
        lastName: memberDetail.value.lastName || null,
        firstName: memberDetail.value.firstName || null,
        gender: memberDetail.value.gender || '',
        birthday: memberDetail.value.birthday
          ? new Date(memberDetail.value.birthday)
          : null,
        cell: memberDetail.value.cell || null,
        email: memberDetail.value.email || null,
        phone: memberDetail.value.phone || '',
        county: memberDetail.value.county || '',
        address: memberDetail.value.address || ''
      }
    }

    const getThousands = num => {
      return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0
    }

    const isEdit = ref(false)
    const toggleEdit = () => {
      // reset when edit will be set true
      if (!isEdit.value) {
        resetState()
        v$.value.$reset()
      }
      isEdit.value = !isEdit.value
    }

    const updateMemberDetail = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        toggleEdit()
        const payload = {
          memberID: memberID.value,
          ...state.value
        }
        if (new Date(payload.birthday).getTime() !== 0) {
          payload.birthday = `${new Date(
            payload.birthday
          ).getFullYear()}-${new Date(payload.birthday).getMonth() +
            1}-${new Date(payload.birthday).getDate()}`
        } else {
          payload.birthday = ''
        }
        try {
          const response = await dispatch('member/updateMemberDetail', {
            payload
          })
          await dispatch('member/readMemberDetail', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, getSuccessMessage(response))
          }

          v$.value.$reset()
          return response
        } catch (error) {
          // console.log('get user info error: ' + ', ' + error)
          setAlert(true, false, `更新失敗: ${error}`)
          v$.value.$reset()
        }
      }
    }

    const readMemberDetail = async () => {
      const payload = { memberID: memberID.value }

      try {
        await dispatch('member/readMemberDetail', { payload })
      } catch (error) {
        // console.log('get user info error: ' + ', ' + error)
        setAlert(true, false, error)
      }
    }

    await readMemberDetail()

    return {
      memberDetail,
      isEdit,
      toggleEdit,
      state,
      v$,
      updateMemberDetail,
      getThousands,
      cities
    }
  }
}
</script>
<style lang="scss" module>
.required {
  color: $red-200;
}
</style>
